import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment";
import { withStyles, Typography, Grid, Avatar } from "@material-ui/core";
import Person from "@material-ui/icons/Person";

function Mensagem({ classes, mensagem }) {
  const intl = useIntl();

  return (
    <Grid
      container
      spacing={1}
      justify={mensagem.magic === "right" ? "flex-end" : "flex-start"}
      className={classes.content}
    >
      <Grid item xs={1}>
        {mensagem.magic && (
          <Avatar className={classes.avatar}>
            <Person fontSize="small" />
          </Avatar>
        )}
      </Grid>

      <Grid item xs={11}>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            {mensagem.magic && (
              <Typography className={classes.agente} align="left">
                {intl.formatMessage({ defaultMessage: "Mentor IA" })}
              </Typography>
            )}
            {!mensagem.magic && (
              <Typography className={classes.agente} align="left">
                {intl.formatMessage({ defaultMessage: "Você" })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">{moment().format("HH:mm")}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {mensagem.texto.split("\n").map((linha, index) => (
            <>
              {linha && (
                <Typography
                  key={index}
                  className={mensagem.magic ? classes.mensagemMagic : classes.mensagemUsuario}
                  align={mensagem.magic ? "left" : "right"}
                >
                  {linha}
                </Typography>
              )}
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

Mensagem.propTypes = {
  classes: PropTypes.object,
  mensagem: PropTypes.object,
};

export default withStyles(() => ({
  content: {
    marginBottom: 12,
  },
  avatar: {
    width: 28,
    height: 28,
    color: "#797979",
    backgroundColor: "#fafafa",
  },
  agente: {
    fontWeight: "bold",
  },
  mensagemMagic: {
    backgroundColor: "#fafafa",
    borderRadius: 4,
    borderTopLeftRadius: 0,
    padding: "4px 8px",
    marginBottom: 8,
    wordBreak: "break-word",
  },
  mensagemUsuario: {
    backgroundColor: "#797979",
    borderRadius: 4,
    borderTopRightRadius: 0,
    padding: "4px 8px",
    color: "#fafafa",
    marginBottom: 8,
    wordBreak: "break-word",
  },
}))(Mensagem);
