import { Component } from "react";
import PropTypes from "prop-types";

import localStorageManager from "./_common/utils/storage";
import { BUSCA_AVANCADA } from "./_common/utils/storage/constantesStorage";
import { AddLogError } from "./_common/utils/startUpApplication/analyticsProvider/errorAnalyticsService";

class ErrorBoundary extends Component {
  componentDidMount() {
    window.onerror = function(message, file, line, col, error) {
      let errorMessage = `ERROR window.onerror: ${message}\nfile: ${file}\nLine Number: ${line}\nCol Number: ${col}\nError: ${error}`;
      if (
        message ===
        "Uncaught NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node."
      ) {
        errorMessage = `ERROR TELA BRANCA window.onerror removeChild: ${message}\nfile: ${file}\nLine Number: ${line}\nCol Number: ${col}\nError: ${error}`;

        AddLogError({ exception: new Error(errorMessage) });
        window.location.reload(true);
      } else {
        AddLogError({ exception: new Error(errorMessage) });
      }

      return true;
    };
  }

  componentDidCatch(error, info) {
    let errorMessage = `ErrorBoundary ${info?.componentStack} \n error \n${error}`;
    if (error.toString().includes("removeChild")) {
      errorMessage = `ERROR TELA BRANCA ErrorBoundary removeChild ${
        info?.componentStack
      } \n error \n${error} \nBuscaAvançada \n${localStorageManager.getItem(BUSCA_AVANCADA)}`;
      AddLogError({ exception: new Error(errorMessage) });
      window.location.reload(true);
    } else {
      AddLogError({ exception: new Error(errorMessage) });
    }
  }
  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }
  render() {
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ErrorBoundary;
