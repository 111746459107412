import React, { useState } from "react";

import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Button,
  Avatar,
  Popper,
  CardHeader,
  CardActions,
  CardContent,
  withStyles,
  TextField,
  Card,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

import { Flex, Tipografia, Tag, createSnackbar, AjaxBlackout } from "../_common";
import LinkLogoRDMentorIA from "../_common/components/LinkLogoRDMentorIA";
import iconeRedondo from "../_common/icones/Logo_Redondo_RD_Station.svg";
import AutoFixOutline from "../_common/icones/AutoFixOutline";

function ComposerMentorIA({
  escreverConteudoMagicWrite,
  textoBuscaMagicWrite = "",
  atualizarTextoPesquisado,
  classes,
}) {
  const intl = useIntl();

  const [perguntaMagic, setPerguntaMagic] = useState(textoBuscaMagicWrite);
  const [validarPergunta, setValidarPergunta] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);

  async function buscarMagicWrite() {
    if (perguntaMagic === "") {
      setValidarPergunta(true);
      return;
    }

    atualizarTextoPesquisado(perguntaMagic);

    try {
      AjaxBlackout.Show(iconeRedondo);
      const resp = await axios.get(`/api/pipeline/IntegracaoMagicWrite/Consultar?prompt=${perguntaMagic}`);
      if (resp.data) {
        escreverConteudoMagicWrite(perguntaMagic, resp.data);
        fecharMagicComposer();
      }
    } catch (erro) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Não foi possível realizar a sua pesquisa" }));
    } finally {
      AjaxBlackout.Hide();
    }
  }

  const fecharMagicComposer = () => {
    setAnchorEl(null);
  };

  const abrirMagicComposer = e => {
    if (anchorEl === null) setAnchorEl(e);
    else setAnchorEl(null);
  };

  return (
    <div>
      <Avatar
        aria-label="recipe"
        src={iconeRedondo}
        id="iconeMagicWrite"
        variant="contained"
        onClick={e => abrirMagicComposer(e.currentTarget)}
        className={classes.avatarMagicWrite}
        style={{ cursor: "pointer" }}
      />
      <Popper
        className={classes.popper}
        open={!!anchorEl}
        placement="top-end"
        disablePortal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "viewport",
            padding: 15,
            options: { padding: 15 },
          },
          flip: {
            enabled: true,
            boundariesElement: "viewport",
            behavior: "flip",
            padding: 15,
            options: { padding: 15 },
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        anchorEl={anchorEl}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Card className={classes.card} square>
          <CardHeader
            className={classes.cardHeader}
            avatar={<LinkLogoRDMentorIA />}
            disableTypography
            title={
              <Flex justifyContent="space-between">
                <Flex>
                  <Tag descricao={intl.formatMessage({ defaultMessage: "Novo!" })} />
                </Flex>
                <IconButton aria-label="Close" className={classes.closeButton} onClick={fecharMagicComposer}>
                  <CloseIcon />
                </IconButton>
              </Flex>
            }
          />
          <CardContent className={classes.cardContent}>
            <Flex>
              <Tipografia tipo="textoSidebar" cor="darkSecondaryText" className={classes.espacamento}>
                {intl.formatMessage({
                  defaultMessage: "Ao clicar em ESCREVER qualquer texto no corpo do template será sobreposto.",
                })}
              </Tipografia>
            </Flex>
            <Flex>
              <TextField
                fullWidth
                placeholder={intl.formatMessage({
                  defaultMessage: "Escreva um e-mail de follow-up...",
                })}
                name="textoBuscaMagicWrite"
                variant="outlined"
                multiline
                rows="8"
                className={classes.campoBusca}
                onChange={e => {
                  setPerguntaMagic(e.target.value);
                }}
                value={perguntaMagic}
                error={validarPergunta && perguntaMagic === ""}
                required
                helperText={perguntaMagic === "" && intl.formatMessage({ defaultMessage: "Campo obrigatório*" })}
              />
            </Flex>
          </CardContent>
          <CardActions className={classes.barraBotoes}>
            <Flex>
              <Button
                key="btnEscreverMagic"
                variant="contained"
                color="primary"
                id="btnEscreverMagic"
                onClick={buscarMagicWrite}
              >
                <AutoFixOutline className={classes.iconeEscreverMagic} />{" "}
                {intl.formatMessage({ defaultMessage: "Escrever" })}
              </Button>
            </Flex>
          </CardActions>
        </Card>
      </Popper>
    </div>
  );
}

ComposerMentorIA.propTypes = {
  escreverConteudoMagicWrite: PropTypes.func,
  textoBuscaMagicWrite: PropTypes.string,
  atualizarTextoPesquisado: PropTypes.func,
};

export default withStyles(theme => ({
  avatarMagicWritePopper: {
    width: 24,
    height: 24,
  },
  avatarMagicWrite: {
    left: "-23px !important",
  },
  iconeEscreverMagic: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  card: {
    width: 550,
    height: 354,
    border: 0,
    boxShadow: "none",
    margin: "12px 12px",
  },
  cardContent: {
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  campoBusca: {
    fontSize: 14,
  },
  barraBotoes: {
    marginLeft: 12,
  },
  cardHeader: {
    paddingTop: 4,
  },
  closeButton: {
    position: "absolute",
    right: 12,
    top: 8,
  },
  tamanhoTexto: {
    fontSize: "12px !important",
  },
  espacamento: {
    marginTop: 12,
  },
  arrow: {
    position: "absolute",
    fontSize: 20,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  popper: {
    border: "1px solid #0000003B",
    boxShadow: "0px 20px 10px 0px #0000001F,-2px 12px 11px 0px #00000024,0px 3px 5px -1px #00000033",
    borderRadius: 5,
    backgroundColor: "#fff",
    zIndex: 1,
    top: "-10px !important",
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.common.white} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
      },
    },
  },
}))(ComposerMentorIA);
